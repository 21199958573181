input.anniversary-answer {
    border: none;
    outline: none;

    border-radius: 0.5rem;
    font-size: calc(10px + 2vmin);

    background-color: #546891;
    color: white;

    padding: 0.5rem;
    margin: 1.5rem;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(185, 184, 184);
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgb(185, 184, 184);;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: rgb(185, 184, 184);;
}

div.anniversary_answer {
    transition: background-color .5s ease-in-out;
}

div.anniversary_answer--correct {
    background-color: #768161;
}

div.anniversary_answer--incorrect {
    background-color: #930712;
}
