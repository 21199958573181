.postcard {
    position: absolute;

    width: 100%;
    height: 100%;

    background-image: url("/img/jemaa.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: opacity 20s ease-in;

    pointer-events: none;
}

.postcard--hidden {
    opacity: 0;
}

.postcard--visible {
    opacity: 1;
}

.postcard__text {
    width: 75%;
    height: 75%;

    max-width: 60rem;
    max-height: 30rem;

    background-color: rgba(0, 0, 0, 0.5);
    padding: 1rem;
    border-radius: 0.5rem;
}