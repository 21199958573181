.container {
    background-color: #282c34;
    height: 100vh;
    width: 100vw;

    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    font-size: calc(10px + 2vmin);
    color: white;
}
