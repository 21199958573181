.heart-container {
    width: 500px;
    height: 450px;

    max-width: 90%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.heart {
    width: 100%;

    animation-name: bump;
    animation-duration: 1.7s;
    animation-iteration-count: infinite;
}

@keyframes bump {
    0% {width: 100%;}
    50% {width: 85%;}
    100% {width: 100%;}
}
